import { ItemType } from "@app/api/folders/helper-schemas";
import {
	IItemsHierarchy,
	IMultipleSelectItem,
} from "@app/components/admin/deep-multiple-select-wth-search";
import { IFolderModel } from "@app/models/folder";
import { IUserFolderProgressModel } from "@app/models/user-folder-progress";
import { inject } from "@app/modules";
import { IModel } from "m-model-core";
import { arrayToObject } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";
import ClonnableHierarchyInfoService from "../clonnable";
import {
	HierarchyItemType,
	IParentInfo,
	IParentInfoInstance,
} from "../interfaces";

export default class FolderHierarchyService extends ClonnableHierarchyInfoService {
	private readonly _Folder: IFolderModel;
	private readonly _UserFolderProgress: IUserFolderProgressModel;

	constructor() {
		const FolderModel = inject("FolderModel");
		const FolderHierarchyModel = inject("FolderHierarchyModel");
		const UserFolderProgressModel = inject("UserFolderProgressModel");
		super(
			FolderHierarchyModel as any,
			FolderModel as any,
			inject("CourseModel"),
			HierarchyItemType.folder
		);
		this._Folder = FolderModel;
		this._UserFolderProgress = UserFolderProgressModel;
	}

	private fetchParentInfoRecursivelySync(
		folderId: ObjectId,
		parentInfo: IParentInfo
	) {
		const folder = this._Folder.findByIdSync(folderId);
		if (!folder) return;
		if (!folder.items) return;
		for (const item of folder.items) {
			if (item.type !== ItemType.folder) continue;
			parentInfo[item.id] = folder._id;
			this.fetchParentInfoRecursivelySync(item.id, parentInfo);
		}
	}

	public setItemParentSync(
		courseId: ObjectId,
		folderId: ObjectId,
		parentId: ObjectId
	): void {
		const parentInfo: IParentInfo = {};
		this.fetchParentInfoRecursivelySync(folderId, parentInfo);
		super.setItemParentSync(courseId, folderId, parentId, parentInfo);
	}

	public setItemParentsForAllCoursesSync(
		folderId: ObjectId,
		parentId: ObjectId
	): void {
		const parentInfo: IParentInfo = {};
		this.fetchParentInfoRecursivelySync(folderId, parentInfo);
		const courseIds = this.getAllCoursesOfFolderSync(parentId);
		for (const courseId of courseIds) {
			super.setItemParentSync(courseId, folderId, parentId, parentInfo);
		}
	}

	public getAllCoursesOfFolderSync(folderId: ObjectId): ObjectId[] {
		const foldersParentInfo = this.parentInfoModel.findManySync(
			{}
		) as IParentInfoInstance[];
		const courseIds: ObjectId[] = [];
		for (const folderParentInfo of foldersParentInfo) {
			if (
				folderParentInfo.rootId === folderId ||
				folderParentInfo.parentInfo[folderId]
			) {
				courseIds.push(folderParentInfo.courseId);
			}
		}
		return courseIds;
	}

	public cloneHierarchySync(courseId: ObjectId, bottomItemId: ObjectId) {
		const updatedIds = super.cloneHierarchySync(courseId, bottomItemId);

		const oldIds = Object.keys(updatedIds);
		function updateCollection<
			InstanceT extends { courseId: ObjectId; folderId: ObjectId },
		>(oldId: ObjectId, model: IModel) {
			model.updateManySync(
				{
					courseId,
					folderId: oldId,
				},
				{
					folderId: updatedIds[oldId],
				}
			);
		}

		// update user_folder_progress, user_folder_level, user_tests collections
		oldIds.forEach((oldId) => {
			updateCollection(oldId, this._UserFolderProgress);
		});

		return updatedIds;
	}

	public getItemsAndHierarchyObject(
		courseId: ObjectId,
		allowedItemTypes: {
			[type in ItemType]?: boolean;
		}
	): {
		items: IMultipleSelectItem<string>[];
		itemsHierarchy: IItemsHierarchy;
		rootId: string;
	} {
		const hierarchyInfo = this.getHierarchyInfoObjectSync(courseId);
		const allFolders = [
			...super.getDescendantIdsSync(
				courseId,
				hierarchyInfo.rootId,
				Infinity,
				hierarchyInfo
			),
			hierarchyInfo.rootId,
		];
		const folders = arrayToObject(
			this._Folder.findManyByIdsSync(allFolders),
			"_id"
		);
		const items: IMultipleSelectItem<string>[] = [];
		const itemsHierarchy: IItemsHierarchy = {
			parentInfo: {},
			childrenInfo: {},
		};

		for (const folderId of Object.keys(folders)) {
			const folder = folders[folderId];
			if (!folder) continue;
			items.push({
				id: folder._id,
				name: folder.name,
				type: ItemType.folder,
			});
			itemsHierarchy.childrenInfo[folder._id] = (
				folder.items || []
			).filter((el) => allowedItemTypes[el.type]);
			for (const subItem of folder.items || []) {
				if (allowedItemTypes[subItem.type]) {
					if (!itemsHierarchy.parentInfo[subItem.type]) {
						itemsHierarchy.parentInfo[subItem.type] = {
							[subItem.id]: folder._id,
						};
					} else {
						itemsHierarchy.parentInfo[subItem.type][subItem.id] =
							folder._id;
					}
					if (subItem.type !== ItemType.folder) items.push(subItem);
				}
			}
		}
		return {
			items,
			itemsHierarchy,
			rootId: hierarchyInfo.rootId,
		};
	}
}
