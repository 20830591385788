import { store } from "index";
import { requireLoginForActionPromise } from "../components/require-login-for-action";
import { logout, renewCredentials } from "../redux/actions/user";
import auth from "./auth/controller";
import { createRequests, ICredentials } from "./requests";
import users from "./users/controller";

const api = {
	users,
	auth,
};

export default api;

const getAccessToken = (): string | undefined => {
	try {
		const credentials = JSON.parse(localStorage.credentials);
		return "" + credentials.accessToken;
	} catch (e) {
		return undefined;
	}
};

const getCredentials = (): ICredentials => {
	if (!localStorage.credentials) {
		throw new Error("credentials not set");
	}
	return JSON.parse(localStorage.credentials);
};

const logoutUser = () => {
	store.dispatch(logout());
};

export const Requests = createRequests({
	accessTokenKey: "access_token",
	urlPrefix: process.env.REACT_APP_BACKEND_SERVER
		? process.env.REACT_APP_BACKEND_SERVER
		: "",
	numOfSeccondsToRenewTokenBeforeExpiration: 0,
	requireLoginForActionPromise: requireLoginForActionPromise,
	updateAccessToken: () => {
		const credentials = getCredentials();
		return api.auth
			.updateAccessToken({
				userId: credentials.userId as number,
				refreshToken: credentials.refreshToken,
				accessToken: credentials.accessToken,
			})
			.then((data) => {
				renewCredentials({
					userId: credentials.userId,
					accessToken: data.accessToken,
					refreshToken: data.refreshToken,
				});
				return data;
			});
	},
	initialConfig: {
		headers: {
			access_token: getAccessToken(),
		},
	},
	logoutUser,
});

window.addEventListener("storage", (e: StorageEvent) => {
	if (e.key !== "credentials") return;
	const accessToken = getAccessToken();
	if (typeof accessToken === "string") {
		Requests.renewAccessToken(accessToken);
	} else {
		Requests.renewAccessToken(undefined);
	}
});
