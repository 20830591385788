/* eslint-disable react-hooks/rules-of-hooks */
import { IRFolder, ItemType } from "@app/api/folders/helper-schemas";
import { addLoader } from "@app/commonJavascript";
import LoadingSVG from "@app/components/styles/img/loading-white";
import { ConnectWithHooks } from "@app/hooks/connect-with-hooks";
import {
	useFolder,
	WholeFolderInfo,
	useWholeCourseSkeleton,
} from "@app/hooks/folders";
import { IResourceLoadingInfo } from "@app/hooks/fetch";
import { inject } from "@app/modules";
import { NotUndefined, ObjectId } from "@app/utils/generics";
import { History } from "history";
import React from "react";
import AllFolders from "./all-folders";
import { FolderItemsContainer } from "./folder-items-container";
import {
	FoldersNavigation,
	UpFolderIcon,
	BottomNavigation,
} from "./navigation";
import { ItemPopups } from "./popups/items";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import SelectIcon from "@material-ui/icons/SelectAll";
import ClearIcon from "@material-ui/icons/Clear";
import styles from "./styles/styles.module.css";
import { FolderItemsSelectContainer } from "./select";
import { AllCourses } from "./courses";

interface IProps {
	history: History;
	courseId: ObjectId;
	folderId: ObjectId;
	folder: IResourceLoadingInfo<IRFolder>;
	skeleton: WholeFolderInfo | null;
}

export type ISelection =
	| {
			type: ItemType;
			id: ObjectId;
			viewType: "View" | "Edit" | "RELOCATE";
	  }
	| {
			type: ItemType;
			id?: undefined;
			viewType: "Add";
	  }
	| {
			viewType: "COPY_ITEMS";
			items: { type: ItemType; id: ObjectId }[];
			createNewFolder: boolean;
			isDeepCopy: boolean;
	  };

interface IState {
	contextMenuSelection: ISelection | null;
	viewMode: "regular" | "select";
	selectedItems: Record<ItemType, Set<string>>;
}

class Folder extends React.Component<IProps, IState> {
	state: IState = {
		contextMenuSelection: null,
		viewMode: "regular",
		selectedItems: {
			[ItemType.card]: new Set(),
			[ItemType.file]: new Set(),
			[ItemType.folder]: new Set(),
			[ItemType.question]: new Set(),
			[ItemType.test]: new Set(),
			[ItemType.text]: new Set(),
		},
	};

	private readonly FolderHierarchyService = inject("FolderHierarchyService");
	private readonly _FoldersController = inject("FoldersController");
	private readonly _FilesController = inject("FilesController");
	private readonly _CardsController = inject("CardsController");
	private readonly _QuestionsController = inject("QuestionsController");
	private readonly _TestsController = inject("TestsController");

	componentDidMount() {
		this._FoldersController.getById(
			{
				_id: this.props.folderId,
				courseId: this.props.courseId,
			},
			true
		);
	}

	gotoParentFolder = () => {
		const parentId = this.FolderHierarchyService.getParentIdSync(
			this.props.courseId,
			this.props.folderId
		);
		if (!parentId) return;
		this.props.history.push(
			`/admin/courses/${this.props.courseId}/folders/${parentId}`
		);
	};

	handleGoToImportClick = () => {
		this.props.history.push(
			`/admin/courses/${this.props.courseId}/folders/${this.props.folderId}/bulk-import`
		);
	};

	onItemSelect = (type: ItemType, _id: ObjectId) => {
		if (type === ItemType.folder) {
			this.props.history.push(
				`/admin/courses/${this.props.courseId}/folders/${_id}`
			);
			return;
		}
		this.setState({
			contextMenuSelection: {
				type,
				id: _id,
				viewType: "View",
			},
		});
	};

	onItemEdit = (type: ItemType, _id: ObjectId) => {
		this.setState({
			contextMenuSelection: {
				type,
				id: _id,
				viewType: "Edit",
			},
		});
	};

	onItemRemove = (type: ItemType, _id: ObjectId) => {
		let fn: (() => Promise<any>) | null = null;
		switch (type) {
			case ItemType.folder:
				fn = () =>
					this._FoldersController.deleteById({
						_id,
						courseId: this.props.courseId,
					});
				break;
			case ItemType.file:
				fn = () =>
					this._FilesController.deleteById({
						_id,
						courseId: this.props.courseId,
					});
				break;
			case ItemType.card:
				fn = () =>
					this._CardsController.deleteById({
						_id,
						folderId: this.props.folderId,
						courseId: this.props.courseId,
					});
				break;
			case ItemType.question:
				fn = () =>
					this._QuestionsController.deleteById({
						_id,
						folderId: this.props.folderId,
						courseId: this.props.courseId,
					});
				break;
			case ItemType.test:
				fn = () =>
					this._TestsController.deleteById({
						courseId: this.props.courseId,
						folderId: this.props.folderId,
						_id,
					});
				break;
			case ItemType.text:
				fn = () =>
					this._QuestionsController.deleteText({
						courseId: this.props.courseId,
						folderId: this.props.folderId,
						textId: _id,
					});
				break;
			default:
				break;
		}
		if (!fn) {
			return;
		}
		openConfirmationPopup({
			approveTitle: getFormattedMessage("yes"),
			rejectTitle: getFormattedMessage("no"),
			onApprove: fn,
			text: getFormattedMessage("deleteConfirmation"),
			defaultErrorText: getFormattedMessage("errorAlert"),
			displayRejectButtonAsPrimary: true,
		});
	};

	onPopupClose = () => {
		this.setState({
			contextMenuSelection: null,
		});
	};

	onAddPopupOpen = (type: ItemType) => () => {
		this.setState({
			contextMenuSelection: {
				type,
				viewType: "Add",
			},
		});
	};

	onItemsReorder = (
		items: NotUndefined<IRFolder["items"]>,
		sourceIndex: number,
		destinationIndex: number
	) => {
		const removeLoader = addLoader();
		this._FoldersController
			.reorderItems({
				_id: this.props.folderId,
				courseId: this.props.courseId,
				sourceIndex,
				destinationIndex,
			})
			.then(() => {
				removeLoader();
			})
			.catch((e) => {
				console.log(e);
				removeLoader();
			});
	};

	onItemRelocate = (type: ItemType, _id: ObjectId) => {
		this.setState({
			contextMenuSelection: {
				viewType: "RELOCATE",
				id: _id,
				type,
			},
		});
	};

	closeAllFolders = () => this.setState({ contextMenuSelection: null });

	moveItemToAnotherFolder = (newFolderId: ObjectId) => {
		if (
			!this.state.contextMenuSelection ||
			this.state.contextMenuSelection.viewType !== "RELOCATE"
		) {
			return;
		}
		const selection = this.state.contextMenuSelection;
		const removeLoader = addLoader();
		this._FoldersController
			.getById({
				_id: this.props.folderId,
				courseId: this.props.courseId,
			})
			.then(({ items }) => {
				if (!items) {
					removeLoader();
					return;
				}
				const item = items.find(
					(e) => e.id === selection.id && e.type === selection.type
				);
				if (!item) {
					removeLoader();
					return;
				}
				this._FoldersController
					.moveItemToAnotherFolder({
						courseId: this.props.courseId,
						itemId: item.id,
						itemType: item.type,
						currentFolderId: this.props.folderId,
						newFolderId,
					})
					.then(() => {
						removeLoader();
						this.setState({ contextMenuSelection: null });
					})
					.catch((err) => {
						console.log(err);
						removeLoader();
					});
			})
			.catch(() => {
				removeLoader();
			});
	};

	viewUsersOfTest = (testId: ObjectId) => {
		this.props.history.push(
			`/admin/courses/${this.props.courseId}/folders/${this.props.folderId}/tests/${testId}/users-of-test`
		);
	};

	swichToSelect = () => {
		this.setState({ viewMode: "select", contextMenuSelection: null });
	};

	swichToRegular = () => {
		this.setState({ viewMode: "regular", contextMenuSelection: null });
	};

	onItemsCopy = (
		items: { type: ItemType; id: ObjectId }[],
		createNewFolder: boolean,
		isDeepCopy: boolean
	) => {
		this.setState({
			contextMenuSelection: {
				viewType: "COPY_ITEMS",
				items,
				createNewFolder,
				isDeepCopy,
			},
		});
	};
	onFolderSelect = (args: { courseId: ObjectId; folderId: ObjectId }) => {
		if (
			!this.state.contextMenuSelection ||
			this.state.contextMenuSelection.viewType !== "COPY_ITEMS"
		) {
			return;
		}
		const removeLoader = addLoader();
		this._FoldersController
			.copyFolderItems({
				source: {
					courseId: this.props.courseId,
					folderId: this.props.folderId,
				},
				destination: {
					courseId: args.courseId,
					folderId: args.folderId,
					createNewFolder:
						this.state.contextMenuSelection.createNewFolder,
				},
				items: this.state.contextMenuSelection.items,
				isDeepCopy: this.state.contextMenuSelection.isDeepCopy,
			})
			.then(() => {
				removeLoader();
				this.swichToRegular();
			})
			.catch((e) => {
				console.error(e);
				removeLoader();
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
			});
	};

	render() {
		const { folder, folderId, courseId } = this.props;
		if (!folder.isSuccessfullyLoaded) {
			return <LoadingSVG width={100} height={100} />;
		}

		let myParentId: ObjectId | null = null;
		try {
			myParentId =
				this.FolderHierarchyService.getParentIdSync(
					courseId,
					folderId
				) || null;
		} catch (e) {
			return <LoadingSVG width={100} height={100} />;
		}

		const { viewMode, contextMenuSelection } = this.state;
		return (
			<div className="main">
				<FoldersNavigation
					currentFolderId={folderId}
					onItemSelect={this.onItemSelect}
					courseId={courseId}
				/>
				<div className={styles.topContainer}>
					{myParentId && (
						<UpFolderIcon onClick={this.gotoParentFolder} />
					)}
					{!!folder.doc.items && (
						<div className={styles.secondaryIcon}>
							{viewMode === "regular" && (
								<SelectIcon onClick={this.swichToSelect} />
							)}
							{viewMode === "select" && (
								<ClearIcon onClick={this.swichToRegular} />
							)}
						</div>
					)}
				</div>
				{contextMenuSelection && (
					<ItemPopups
						selection={contextMenuSelection}
						onClose={this.onPopupClose}
						folderId={folderId}
						courseId={courseId}
					/>
				)}
				{viewMode === "regular" && !!folder.doc.items && (
					<FolderItemsContainer
						items={folder.doc.items}
						onItemSelect={this.onItemSelect}
						onItemsReorder={this.onItemsReorder}
						onItemEdit={this.onItemEdit}
						onItemRemove={this.onItemRemove}
						onItemRelocate={this.onItemRelocate}
						viewUsersOfTest={this.viewUsersOfTest}
						courseId={courseId}
					/>
				)}
				{viewMode === "select" && !!folder.doc.items && (
					<FolderItemsSelectContainer
						onCopy={this.onItemsCopy}
						items={folder.doc.items}
						folderId={folderId}
						courseId={courseId}
						checkAllDefault={true}
					/>
				)}
				<BottomNavigation
					onAddPopupOpen={this.onAddPopupOpen}
					onGoToImportClick={this.handleGoToImportClick}
				/>
				{contextMenuSelection &&
					contextMenuSelection.viewType === "RELOCATE" && (
						<AllFolders
							courseId={courseId}
							onClose={this.closeAllFolders}
							onFolderChange={this.moveItemToAnotherFolder}
							itemType={contextMenuSelection.type}
							itemId={contextMenuSelection.id}
						/>
					)}
				{contextMenuSelection &&
					contextMenuSelection.viewType === "COPY_ITEMS" && (
						<AllCourses
							currentCourse={courseId}
							currentFolder={folderId}
							onFolderSelect={this.onFolderSelect}
							onClose={this.onPopupClose}
						/>
					)}
			</div>
		);
	}
}

const ConnectedFolder = ConnectWithHooks(Folder, {
	folder: ({ folderId, courseId }) => useFolder({ folderId, courseId }),
	skeleton: ({ courseId }) => useWholeCourseSkeleton({ courseId }),
});

export default ConnectedFolder;
