import React, { FC, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
	CircularProgress,
	Typography,
	Paper,
	Button,
	Box,
	Container,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
	ILoginByExternalPlatformToken,
	loginByExternalPlatformToken,
} from "@app/redux/actions/user";

interface TokenAuthenticationPageParams {
	token: string;
}

interface IDispatchProps {
	loginByExternalPlatformToken: ILoginByExternalPlatformToken;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IOwnProps {}

type IProps = IOwnProps & IDispatchProps;

/**
 * Component that handles the external platform test taking process.
 * It receives a token in the URL, validates it through the backend,
 * and then redirects to the actual test page.
 */
const TokenAuthenticationPage: FC<IProps> = ({
	loginByExternalPlatformToken,
}) => {
	const { token } = useParams<TokenAuthenticationPageParams>();
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		loginByExternalPlatformToken({ token })
			.then((data) => {
				const modellingId =
					data.extraInfo?.externalPlatformMetaData.test_id;
				setLoading(false);
				setTimeout(() => {
					history.replace(`/modelling/${modellingId}`);
				}, 500);
			})
			.catch((e) => {
				setLoading(false);
				setError(e?.message ?? "Unknown Error");
			});
	}, [token, history]);

	if (error) {
		return (
			<Container maxWidth="md">
				<Box mt={10}>
					<Paper elevation={3} style={{ padding: "2rem" }}>
						<Typography variant="h4" color="error" gutterBottom>
							დაფიქსირდა შეცდომა კოდით AUTH1
						</Typography>
						<Box
							mt={3}
							display="flex"
							justifyContent="space-between"
						>
							<Button
								variant="contained"
								color="primary"
								onClick={() => window.location.reload()}
							>
								ხელახლა ცდა
							</Button>
							<Button
								variant="outlined"
								onClick={() =>
									(window.location.href =
										"http://doit.gov.ge/")
								}
							>
								უკან დაბრუნება
							</Button>
						</Box>
					</Paper>
				</Box>
			</Container>
		);
	}

	return (
		<Container maxWidth="sm">
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				height="100vh"
			>
				<CircularProgress size={60} />
			</Box>
		</Container>
	);
};

export default connect<null, IDispatchProps, IOwnProps>(null, {
	loginByExternalPlatformToken,
} as any as IDispatchProps)(TokenAuthenticationPage);
