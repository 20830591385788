import Joi from "@app/utils/joi";
import { IModelingTest, ModelingTestSchema } from "./helper-schemas";
import {
	UserAnswerSchema,
	IUserAnswer,
} from "@tests-core/schemas/questions/contnets/ans-schema";
import { ObjectId, IAnyObj } from "@app/utils/generics";
import {
	FullQuestionSchema,
	ShortQuestionSchema,
	IFullQuestion,
	IShortQuestion,
} from "@tests-core/schemas/questions/helper-schemas";
import { TextSchema, IText } from "@tests-core/schemas/texts/helper-schemas";
import { RGETTestContentsSchema, IRGETTestContents } from "../tests/validators";
import { IRTest, RTestSchema } from "../tests/helper-schemas";

export const APOSTCreateSchema = Joi.object({
	courseId: Joi.objectId().required(),
	folderId: Joi.objectId().required(),
	testId: Joi.objectId().required(),
	subjectId: Joi.number().integer().required(),
	ordinal: Joi.number().integer().greater(0).required(),
	name: Joi.string().required(),
	startTime: Joi.date().required(),
	finishTime: Joi.date().required(),
	maxDuration: Joi.number().greater(0).required(),
	reviewStartTime: Joi.date(),
	reviewFinishTime: Joi.date(),

	extraInfo: Joi.object(),
});
export interface IAPOSTCreate {
	courseId: ObjectId;
	folderId: ObjectId;
	testId: ObjectId;
	subjectId: number;
	ordinal: number;
	name: string;
	startTime: Date;
	finishTime: Date;
	maxDuration: number;
	reviewStartTime?: Date;
	reviewFinishTime?: Date;
	extraInfo?: IAnyObj;
}

export const RGETGetOneSchema = ModelingTestSchema;
export type IRGETGetOne = IModelingTest;

export const RPOSTCreateSchema = Joi.object({
	testId: Joi.number().required(),
});
export interface IRPOSTCreate {
	testId: number;
}

export const AnsweredQuestionSchema = Joi.object({
	id: Joi.objectId().required(),
	userAnswer: UserAnswerSchema.required(),
	answeredAt: Joi.date().required(),
});
export interface IAnsweredQuestion {
	id: ObjectId;
	userAnswer: IUserAnswer;
	answeredAt: Date;
}

///

export const AGETBeginSchema = Joi.object({
	testId: Joi.number().integer().required(),
	advanceToNextSection: Joi.boolean(),
});
export interface IAGETBegin {
	testId: number;
	advanceToNextSection?: boolean;
}

export const RGETBeginSchema = Joi.object({
	questions: Joi.array()
		.items(Joi.alternatives(FullQuestionSchema, ShortQuestionSchema))
		.required(),
	texts: Joi.array().items(TextSchema).required(),
	answeredQuestionsInfo: Joi.array().items(
		AnsweredQuestionSchema.keys({
			numAttempts: Joi.number().required(),
			credit: Joi.number(),
		})
	),
	test: RTestSchema.required(),
	startedAt: Joi.date().required(),
	multiSectionalInfo: Joi.object({
		currentTestIndex: Joi.number().required(),
		currentSectionTestId: Joi.objectId().allow(null).required(),
		currentSectionUsedTime: Joi.number().allow(null).required(),
		currentSectionTestStartedAt: Joi.date().allow(null).required(),
		totalTests: Joi.number().required(),
		breakTimeRemaining: Joi.number().required(),
		isBreakTime: Joi.boolean().required(),
		isComplete: Joi.boolean().required(),
	}),
});
export interface IRGETBegin {
	questions: (IFullQuestion | IShortQuestion)[];
	texts: IText[];
	answeredQuestionsInfo?: (IAnsweredQuestion & {
		numAttempts: number;
		credit?: number;
	})[];
	test: IRTest;
	startedAt: Date;
	multiSectionalInfo?: {
		currentTestIndex: number;
		currentSectionTestId: ObjectId | null;
		currentSectionUsedTime: number | null;
		currentSectionTestStartedAt: Date | null;
		totalTests: number;
		breakTimeRemaining: number;
		isBreakTime: boolean;
		isComplete: boolean;
	};
}

///

export const RGETBeginOfflineSchema = Joi.object({
	questions: Joi.array()
		.items(Joi.alternatives(FullQuestionSchema, ShortQuestionSchema))
		.required(),
	texts: Joi.array().items(TextSchema).required(),
});
export interface IRGETBeginOffline {
	questions: (IFullQuestion | IShortQuestion)[];
	texts: IText[];
}

///

export const RGETReviewSchema = RGETBeginSchema;
export type IRGETReview = IRGETBegin;

///

export const APUTSaveSchema = Joi.object({
	testId: Joi.number().integer().required(),
	answers: Joi.array().items(AnsweredQuestionSchema).required(),
	sectionTestId: Joi.objectId(),
	submit: Joi.boolean().required(),
});
export interface IAPUTSave {
	testId: number;
	answers: {
		id: ObjectId;
		userAnswer: IUserAnswer;
		answeredAt: Date;
	}[];
	sectionTestId?: ObjectId;
	submit: boolean;
}

export type IRPUTSave = void;

///

export const RGETGetPublicModellingSchema = RGETTestContentsSchema.keys({
	modelling: ModelingTestSchema.required(),
	test: RTestSchema.required(),
});

export interface IRGETGetPublicModelling extends IRGETTestContents {
	modelling: IModelingTest;
	test: IRTest;
}

///
