import api, { Requests } from "@app/api";
import {
	IAPOSTLogin,
	IAPUTLoginByAccessToken,
	IRPOSTLogin,
	IRPUTAccessTokens,
} from "@app/api/auth/validators";
import { IRootActions, IRootState } from "@app/redux";
import { IStateUser } from "@app/redux/reducers/user";
import {
	LOAD_USER_INFO,
	USER_AUTHORIZATION_SUCCESS,
	USER_LOG_OUT,
} from "@app/redux/action-types";
import { setUserForAnalytics } from "@app/utils/events";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ICredentials } from "@app/api/requests";

interface ILoginAction {
	type: typeof USER_AUTHORIZATION_SUCCESS;
	userData: IRPOSTLogin["userData"];
}

const loginAction = (userData: IRPOSTLogin["userData"]): ILoginAction => {
	setUserForAnalytics(userData);
	return {
		type: USER_AUTHORIZATION_SUCCESS,
		userData,
	};
};

const dipatchLoginActions = (
	dispatch: ThunkDispatch<IRootState, null, IRootActions>,
	res: any
) => {
	renewCredentials({
		userId: res.userData.id,
		accessToken: res.accessToken,
		refreshToken: res.refreshToken,
	});
	dispatch(loginAction(res.userData));
};

export type ILogin = (data: IAPOSTLogin) => Promise<IRPOSTLogin>;
// tslint:disable-next-line:max-line-length
export const login =
	(
		data: IAPOSTLogin
	): ThunkAction<ReturnType<ILogin>, IRootState, null, IRootActions> =>
	(dispatch): ReturnType<ILogin> => {
		return api.auth.login(data).then((res) => {
			console.log(res);
			dipatchLoginActions(dispatch, res);
			return res;
		});
	};

export type ILoginByToken = (
	data: IAPUTLoginByAccessToken
) => Promise<IRPUTAccessTokens>;
// tslint:disable-next-line:max-line-length
export const loginByToken =
	(
		data: IAPUTLoginByAccessToken
	): ThunkAction<ReturnType<ILoginByToken>, IRootState, null, IRootActions> =>
	(dispatch): ReturnType<ILoginByToken> => {
		return api.auth.loginByToken(data).then((res) => {
			console.log(res);
			dipatchLoginActions(dispatch, res);
			return res;
		});
	};

export type ILoginByExternalPlatformToken = (data: {
	token: string;
}) => Promise<IRPOSTLogin>;
// tslint:disable-next-line:max-line-length
export const loginByExternalPlatformToken =
	(data: {
		token: string;
	}): ThunkAction<
		ReturnType<ILoginByExternalPlatformToken>,
		IRootState,
		null,
		IRootActions
	> =>
	(dispatch): ReturnType<ILoginByToken> => {
		return api.auth.loginByExternalPlatformToken(data).then((res) => {
			console.log(res);
			dipatchLoginActions(dispatch, res);
			return res;
		});
	};

export interface ILogoutAction {
	type: typeof USER_LOG_OUT;
}
const logout = (): ILogoutAction => {
	console.trace();
	try {
		localStorage.clear();
	} finally {
	}
	window.scroll(0, 0);
	return {
		type: USER_LOG_OUT,
	};
};
export { logout };

interface ILoadUserAction {
	type: typeof LOAD_USER_INFO;
	user: IStateUser;
}
export const loadUser = (user: IStateUser): ILoadUserAction => {
	if (user.userData) {
		setUserForAnalytics(user.userData);
	}
	return {
		type: LOAD_USER_INFO,
		user,
	};
};

export const renewCredentials = (credentials: ICredentials) => {
	localStorage.setItem(
		"credentials",
		JSON.stringify({
			userId: credentials.userId,
			accessToken: credentials.accessToken,
			refreshToken: credentials.refreshToken,
		})
	);
	Requests.renewConfigByCredentials(credentials);
};

export type UserActions = ILoginAction | ILoadUserAction | ILogoutAction;
