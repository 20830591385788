import { css } from "emotion";

const styles = {
	textField: css`
		margin-bottom: 16px;
	`,
	textarea: css`
		width: 100%;
		margin-bottom: 16px;
		min-height: 100px;
		padding: 8px;
		font-family: monospace;
		font-size: 14px;
	`,
	button: css`
		margin-bottom: 16px;
	`,
	list: css`
		margin-bottom: 16px;
	`,
	listItem: css`
		border: 1px solid #ccc;
		margin-bottom: 8px;
		padding: 8px;
		border-radius: 4px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	`,
	listItemText: css`
		flex-grow: 1;
	`,
	listItemStatus: css`
		margin-left: 16px;
		display: flex;
		align-items: center;
	`,
	statusIcon: css`
		margin-right: 4px;
	`,
	errorMessage: css`
		color: red;
		margin-bottom: 16px;
	`,
	successAlert: css`
		margin-bottom: 16px;
	`,
	hideButton: css`
		margin-top: 8px;
	`,
	banner: css`
		margin-bottom: 16px;
		padding: 8px;
	`,
	block: css`
		display: block;
	`,
};

export default styles;
