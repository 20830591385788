import React, { useState, useCallback, useRef } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { ObjectId } from "@app/utils/generics";
import classNames from "classnames";
import Joi from "@app/utils/joi";
import axios, { AxiosError } from "axios";
import styles from "./styles";
import { inject } from "@app/modules";
import { Language } from "@tests-core/schemas/questions/const";
import { ContentType } from "@tests-core/schemas/questions/contnets/common-schemas";
import { getUniqueId } from "@tests-core/utils/common";

const pastedQuestionsSchema = Joi.array().items(
	Joi.object({
		statement: Joi.string().required(),
		choices: Joi.array()
			.items(
				Joi.object({
					text: Joi.string().required(),
					isCorrect: Joi.boolean().optional(),
				})
			)
			.required(),
	})
);
interface PastedQuestion {
	statement: string;
	choices: { text: string; isCorrect?: boolean }[];
}

const exampleJSON = JSON.stringify([
	{
		statement: "What is 2 + 2?",
		choices: [
			{ text: "2", isCorrect: false },
			{ text: "4", isCorrect: true },
			{ text: "5", isCorrect: false },
		],
	},
	{
		statement: "Which of the following is the Capital of India?",
		choices: [
			{ text: "Mumbai", isCorrect: false },
			{ text: "Kolkata", isCorrect: false },
			{ text: "Chennai", isCorrect: false },
			{ text: "Delhi", isCorrect: true },
		],
	},
] as PastedQuestion[]);

interface QuestionWithStatus extends PastedQuestion {
	state: "idle" | "pending" | "success" | "error";
}

export const QuestionsImport = React.memo<{
	courseId: ObjectId;
	folderId: ObjectId;
}>(function QuestionsImport({ courseId, folderId }) {
	const [jsonText, setJsonText] = useState("");
	const [validationError, setValidationError] = useState("");
	const [questions, setQuestions] = useState<QuestionWithStatus[] | null>(
		null
	);
	const [isCreatingQuestions, setIsCreatingQuestions] = useState(false);
	const [createQuestionsError, setCreateQuestionsError] = useState("");
	const [showSuccessfulHideButton, setShowSuccessfulHideButton] =
		useState(false);
	const [showSuccessfulQuestions, setShowSuccessfulQuestions] =
		useState(true);
	const [allQuestionsSuccessful, setAllQuestionsSuccessful] = useState(false);

	const validateFields = useCallback(() => {
		setValidationError("");
		try {
			const parsedJson = JSON.parse(jsonText);
			const { error } = pastedQuestionsSchema.validate(parsedJson);
			if (error) {
				setValidationError(`JSON validation error: ${error.message}`);
				return false;
			}
			setQuestions(parsedJson.map((q) => ({ ...q, state: "idle" })));
			return true;
		} catch (e) {
			setValidationError(
				"Invalid JSON format." +
					(!!e && typeof e === "object" && "message" in e
						? e.message
						: "")
			);
			return false;
		}
	}, [jsonText]);

	const handleValidate = () => {
		if (validateFields()) {
			setValidationError("");
		}
	};

	const handleCreateQuestions = useCallback(async () => {
		if (!questions) return;
		setCreateQuestionsError("");
		setIsCreatingQuestions(true);
		setAllQuestionsSuccessful(false);
		setShowSuccessfulHideButton(false);

		const currentQuestions = questions.map(
			(q): QuestionWithStatus => ({
				...q,
				state: "pending",
			})
		);
		setQuestions(currentQuestions);

		let questionCreationSuccessfulCount = 0;
		const tempQuestions = [...currentQuestions];

		for (let i = 0; i < questions.length; i++) {
			const question = questions[i];
			try {
				const choiceIds = getUniqueId([], question.choices.length);
				const QuestionsController = inject("QuestionsController");
				const correctChoices = question.choices.filter(
					(c) => c.isCorrect
				);
				if (correctChoices.length === 0) {
					throw new Error(
						`At least one correct choice is required for Q${i + 1}`
					);
				}
				const hasMultipleCorrectChoices = correctChoices.length > 1;
				await QuestionsController.add({
					courseId: courseId,
					question: {
						content: {
							type: ContentType.MultipleChoice,
							statement: {
								id: 1,
								text: question.statement,
							},
							choices: question.choices.map((c, index) => ({
								id: choiceIds[index],
								text: c.text,
								score: c.isCorrect ? 1 : undefined,
							})),
							canSelectMultiple: hasMultipleCorrectChoices,
						},
						lang: Language.Georgian,
						reviewed: 0,
						visible: true,
					},
					testIds: [],
					taskTypeIds: [],
					topicIds: [],
					folderIds: [folderId],
				});
				tempQuestions[i] = { ...question, state: "success" };
				questionCreationSuccessfulCount++;
				// update status of current question
			} catch (error) {
				const questionError = error as AxiosError;
				tempQuestions[i] = { ...question, state: "error" };
				setCreateQuestionsError(
					`Question creation failed for question ${i + 1}: ${questionError.message || questionError.response?.data?.message || "Unknown error"}`
				);
				setIsCreatingQuestions(false);
				return;
			} finally {
				setQuestions(tempQuestions);
			}
		}

		setIsCreatingQuestions(false);
		setShowSuccessfulHideButton(questionCreationSuccessfulCount > 0);

		if (questionCreationSuccessfulCount === questions.length) {
			setAllQuestionsSuccessful(true);
			setTimeout(() => {
				alert("All questions created successfully!");
				setAllQuestionsSuccessful(false); // Reset after alert is displayed
			}, 100);
		}
	}, [questions, courseId, folderId]);

	const handleTextareaChange = (event) => {
		setJsonText(event.target.value);
		setQuestions(null);
		setValidationError("");
		setCreateQuestionsError("");
		setShowSuccessfulHideButton(false);
		setAllQuestionsSuccessful(false);
	};

	const handleHideSuccessfulQuestions = () => {
		if (!questions) return;
		const successfulQuestionsData = questions
			.map((question, index) => ({ question, index }))
			.filter((q) => q.question.state === "success");
		const successfulQuestionsIndices = successfulQuestionsData.map(
			(q) => q.index
		);

		const parsedJson = JSON.parse(jsonText);
		const updatedJson = parsedJson.filter(
			(_, index) => !successfulQuestionsIndices.includes(index)
		);
		setJsonText(JSON.stringify(updatedJson, null, 2));
		setShowSuccessfulHideButton(false);
		setQuestions(null);
		setValidationError("");
		setCreateQuestionsError("");
		setShowSuccessfulQuestions(false);

		setTimeout(() => {
			setShowSuccessfulQuestions(true); // Re-enable rendering after text area update for better UX
		}, 0);
	};

	const handleRetry = () => {
		setCreateQuestionsError("");
		handleCreateQuestions();
	};

	const renderStatusIcon = (state) => {
		switch (state) {
			case "pending":
				return (
					<CircularProgress size={20} className={styles.statusIcon} />
				);
			case "success":
				return <CheckCircleIcon className={styles.statusIcon} />;
			case "error":
				return (
					<ErrorIcon color="error" className={styles.statusIcon} />
				);
			case "idle":
			default:
				return (
					<PauseCircleOutlineIcon
						color="action"
						className={styles.statusIcon}
					/>
				);
		}
	};

	return (
		<div className="main main2" style={{ paddingTop: 20 }}>
			<Typography variant="h5" gutterBottom>
				Question Import Wizard
			</Typography>

			<Typography variant="subtitle1" gutterBottom>
				Paste JSON questions array. Example:
				<br />
				{exampleJSON}
			</Typography>
			<TextareaAutosize
				className={styles.textarea}
				placeholder="Paste here"
				value={jsonText}
				onChange={handleTextareaChange}
				rowsMax={4}
			/>

			{validationError && (
				<div className={classNames(styles.banner, styles.errorMessage)}>
					{validationError}
				</div>
			)}
			{createQuestionsError && (
				<div className={classNames(styles.banner, styles.errorMessage)}>
					{createQuestionsError}
				</div>
			)}
			{allQuestionsSuccessful && (
				<div className={classNames(styles.banner, styles.successAlert)}>
					All questions created successfully!
				</div>
			)}

			{!questions ? (
				<Button
					className={styles.button}
					variant="contained"
					color="primary"
					onClick={handleValidate}
				>
					Validate
				</Button>
			) : (
				<>
					{showSuccessfulQuestions && questions.length > 0 && (
						<List className={styles.list}>
							{questions.map((question, index) => (
								<ListItem
									key={index}
									className={styles.listItem}
								>
									<ListItemText
										className={styles.listItemText}
										primary={`Q${index + 1}: ${question.statement}`}
										secondary={
											<span className={styles.block}>
												{question.choices.map(
													(c, ci) => (
														<span
															key={ci}
															className={
																styles.block
															}
														>
															{c.isCorrect
																? `✓ ${c.text}`
																: c.text}
														</span>
													)
												)}
											</span>
										}
									/>
									<div className={styles.listItemStatus}>
										{renderStatusIcon(question.state)}
										{question.state === "idle"
											? "Ready to be created"
											: question.state === "pending"
												? "Creating..."
												: question.state === "success"
													? "Created"
													: "Error"}
									</div>
								</ListItem>
							))}
						</List>
					)}

					<Button
						className={styles.button}
						variant="contained"
						color="primary"
						onClick={
							isCreatingQuestions
								? handleRetry
								: handleCreateQuestions
						}
						disabled={isCreatingQuestions}
					>
						{isCreatingQuestions ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							"Create Questions"
						)}
					</Button>
					{showSuccessfulHideButton && (
						<Button
							className={styles.hideButton}
							variant="outlined"
							startIcon={<VisibilityOffIcon />}
							onClick={handleHideSuccessfulQuestions}
						>
							Hide successfully created questions from this page
						</Button>
					)}
				</>
			)}
		</div>
	);
});
