/* eslint-disable no-restricted-globals */
import { IModelingTest } from "@app/api/modeling/helper-schemas";
import {
	ITestTypeSettings,
	TestTypeAnswersShowTime,
} from "@app/api/test-types/helper-schemas";
import { IRTest, IUserTestQuestionInfo } from "@app/api/tests/helper-schemas";
import { inject } from "@app/modules";
import triggerEvent from "@app/utils/events";
import { ObjectId } from "@app/utils/generics";
import { getFormattedMessage } from "@app/utils/locale";
import { tryPromiseMultipleTimes } from "@app/utils/promises";
import { newContent } from "@tests-core/components/questions/contents/new-content";
import {
	IFinishPageProps,
	ITestFinishArgs,
} from "@tests-core/components/tests";
import {
	IFullQuestion,
	IShortQuestion,
} from "@tests-core/schemas/questions/helper-schemas";
import { IText } from "@tests-core/schemas/texts/helper-schemas";
import { History } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import { PrimaryButton } from "../styles/buttons";
import SvgLoading from "../styles/img/loading";
import { UniversalTestWrapper2 } from "../users/tests/wrapper-2";
import { openConfirmationPopup } from "../widgets/confirmation-popup";
import TouLogoSrc from "./imgs/diig.jpg";
import { testDesign } from "./public";
import styles from "./styles.module.css";

interface IOwnProps {
	match: match<{ modellingId: ObjectId }>;
	history: History;
}

enum Mode {
	beginning,
	test,
	break,
	review,
	end,
}

interface IState {
	mode: Mode;
	loading: boolean;
	isFinishLoading?: boolean;
	questions?: (IShortQuestion | IFullQuestion)[];
	texts?: IText[];
	test?: IRTest;
	modellingTest?: IModelingTest;
	defaultCheckedAnswers?: IUserTestQuestionInfo[];
	startedAt?: Date;
	duration?: number;
	multiSectionalInfo?: {
		currentTestIndex: number;
		currentSectionTestId: ObjectId | null;
		totalTests: number;
		isBreakTime?: boolean;
		breakTimeRemaining?: number;
		maxTimePerTest?: number;
		isComplete?: boolean;
	};
	breakTimeInterval?: NodeJS.Timeout;
}

class Modelling extends React.Component<IOwnProps, IState> {
	modellingId = +this.props.match.params.modellingId;

	state: IState = {
		mode: Mode.beginning,
		loading: true,
	};

	resultsPage = false;

	_ModelingTestsController = inject("ModelingController");

	componentDidMount() {
		// Get the base modeling test object
		this._ModelingTestsController
			.get({ testId: this.modellingId })
			.then((test) => {
				this.setState({
					loading: false,
					modellingTest: test,
				});
				if (this.resultsPage) {
					localStorage.setItem("has-clicked-survey", "true");
					this.onStartReviewing().then(() => {
						setTimeout(() => {
							this.forceFullyFinish();
						}, 100);
					});
				}
			})
			.catch(() => {
				alert("დაფიქსირდა შეცდომა");
			});
	}

	componentWillUnmount() {
		if (this.state.breakTimeInterval) {
			clearInterval(this.state.breakTimeInterval);
		}
	}

	onBeginTest = () => {
		this.setState({ loading: true });
		tryPromiseMultipleTimes({
			delayBetweenTrials: 100,
			getPromise: () =>
				this._ModelingTestsController
					.begin({ testId: this.modellingId })
					.then((data) => {
						const multiSectionalInfo = data.multiSectionalInfo;
						const testDuration = this.state.modellingTest
							? getDuration(this.state.modellingTest)
							: undefined;

						const userAnswers: IUserTestQuestionInfo[] | undefined =
							!data.answeredQuestionsInfo
								? undefined
								: data.answeredQuestionsInfo.map((e) => ({
										id: e.id,
										userAnswer: e.userAnswer,
										answeredAt: e.answeredAt,
										numAttempts: e.numAttempts,
										credit: e.credit || 0,
									}));
						this.setState({
							questions: data.questions,
							texts: data.texts,
							test: data.test,
							multiSectionalInfo,
							defaultCheckedAnswers: userAnswers,
							startedAt:
								multiSectionalInfo?.currentSectionTestStartedAt ??
								data.startedAt,
							duration: testDuration,
						});

						// If the backend says it's already break time or complete
						if (multiSectionalInfo?.isComplete) {
							this.resultsPage = true;
							this.setState({
								loading: false,
								mode: Mode.end,
								questions: [], // do not display questions
								defaultCheckedAnswers: [],
							});
							return;
						}
						if (multiSectionalInfo?.isBreakTime) {
							this.setState({
								mode: Mode.break,
								loading: false,
							});
							this.startBreakTimeCountdown();
							return;
						}

						// Otherwise, we're in test mode
						this.setState({
							mode: multiSectionalInfo?.isComplete
								? Mode.end
								: Mode.test,
							loading: false,
						});

						triggerEvent(
							{
								category: "Test",
								action: "Modelling test",
								label: "start",
							},
							{
								modellingId: +this.modellingId,
							}
						);
					}),
			maxTrials: 2,
		});
	};

	// Optional review logic if your backend still supports a separate review endpoint
	onStartReviewing = () => {
		this.setState({
			loading: true,
		});
		return this._ModelingTestsController
			.review({ testId: this.modellingId })
			.then((data) => {
				this.setState({
					mode: Mode.review,
					questions: data.questions as IShortQuestion[],
					texts: data.texts,
					loading: false,
					test: data.test,
					defaultCheckedAnswers: !data.answeredQuestionsInfo
						? []
						: data.answeredQuestionsInfo.map((e) => ({
								id: e.id,
								userAnswer: e.userAnswer,
								answeredAt: e.answeredAt,
								numAttempts: e.numAttempts,
								credit: e.credit || 0,
							})),
				});
			});
	};

	save = async (args: ITestFinishArgs, finishSectionOrTest: boolean) => {
		const { multiSectionalInfo } = this.state;
		let isLastTest = true;
		if (
			multiSectionalInfo &&
			this.state.modellingTest?.is_multi_sectional
		) {
			isLastTest =
				multiSectionalInfo.currentTestIndex + 1 >=
				multiSectionalInfo.totalTests;
		}
		const after = () => {
			if (!finishSectionOrTest) {
				this.setState({
					loading: false,
				});
				return;
			}
			const { multiSectionalInfo } = this.state;
			if (
				multiSectionalInfo &&
				this.state.modellingTest?.is_multi_sectional
			) {
				this.setState({
					loading: false,
					mode: isLastTest ? Mode.end : Mode.break,
					multiSectionalInfo: {
						...multiSectionalInfo,
						isBreakTime: !isLastTest,
						isComplete: isLastTest,
					},
				});
				if (!isLastTest) {
					this.startBreakTimeCountdown();
				}
			} else {
				this.setState({
					loading: false,
					mode: Mode.end,
				});
			}
		};
		if (this.resultsPage) {
			return Promise.resolve().then(after);
		}
		return this._ModelingTestsController
			.save({
				testId: this.modellingId,
				answers: args.userAnswers
					.filter((e) => !!e && e.userAnswer != null)
					.map((e) => ({
						id: e.questionId,
						userAnswer: e.userAnswer!,
						answeredAt: e.dates.lastAnsweredAt || new Date(),
					})),
				sectionTestId: this.state.modellingTest?.is_multi_sectional
					? (this.state.multiSectionalInfo?.currentSectionTestId ??
						undefined)
					: undefined,
				submit: finishSectionOrTest,
			})
			.then(after);
	};

	handleTestError = (e: any) => {
		openConfirmationPopup({
			text: "დაფიქსირდა შეცდომა. თქვენი ნაწერი ვერ შეინახა. სცადეთ თავიდან დაწერა",
		});
		throw e;
	};

	onFinish = async (args: ITestFinishArgs) => {
		return tryPromiseMultipleTimes({
			getPromise: () => this.save(args, true),
			maxTrials: 6,
			delayBetweenTrials: 100,
		})
			.then(() => {
				this.setState({
					isFinishLoading: false,
				});
				triggerEvent(
					{
						category: "Test",
						action: "Modelling test",
						label: "finish",
					},
					{
						modellingId: +this.modellingId,
					}
				);
			})
			.catch(this.handleTestError);
	};

	onCloseTabSave = async (args: ITestFinishArgs) => {
		return tryPromiseMultipleTimes({
			getPromise: () => this.save(args, false),
			maxTrials: 6,
			delayBetweenTrials: 100,
		}).catch(this.handleTestError);
	};

	/**
	 * Called before the test wrapper calls onFinish.
	 * If it's a multi-sectional test and not the last section,
	 * we just finish the current section and move to break mode
	 * (meaning we don't want to finalize the entire modeling test).
	 */
	preFinishHook = (): Promise<{ finish: boolean }> => {
		if (this.resultsPage) {
			return Promise.resolve({ finish: true });
		}

		const { multiSectionalInfo } = this.state;
		if (
			multiSectionalInfo &&
			this.state.modellingTest?.is_multi_sectional
		) {
			const isLastTest =
				multiSectionalInfo.currentTestIndex + 1 >=
				multiSectionalInfo.totalTests;
			return new Promise((resolve) => {
				openConfirmationPopup({
					text: isLastTest
						? `ნამდვილად გსურთ დაასრულოთ მოდელირება? (ეს არის ბოლო ტესტის ნაწილი)`
						: `ნამდვილად გსურთ დაასრულოთ ეს ტესტის ნაწილი? (#${multiSectionalInfo.currentTestIndex + 1}/${multiSectionalInfo.totalTests})`,
					approveTitle: getFormattedMessage("yes"),
					rejectTitle: getFormattedMessage("no"),
					onApprove: async () => {
						this.setState({
							isFinishLoading: true,
						});
						resolve({ finish: true });
					},
					onReject: () => {
						resolve({ finish: false });
					},
				});
			});
		}

		// If it's a single-section or normal scenario, just confirm finishing once
		return new Promise((resolve) => {
			openConfirmationPopup({
				text: "ნამდვილად გსურთ მოდელირების დასრულება?",
				approveTitle: getFormattedMessage("yes"),
				rejectTitle: getFormattedMessage("no"),
				onApprove: () => {
					resolve({ finish: true });
				},
				onReject: () => {
					resolve({ finish: false });
				},
			});
		});
	};

	startBreakTimeCountdown = () => {
		if (this.state.breakTimeInterval) {
			clearInterval(this.state.breakTimeInterval);
		}
		const breakTimeInterval = setInterval(() => {
			this.setState((prevState) => {
				const info = prevState.multiSectionalInfo;
				if (!info?.isBreakTime || !info.breakTimeRemaining) {
					clearInterval(breakTimeInterval);
					return prevState;
				}
				const newRemaining = info.breakTimeRemaining - 1;
				if (newRemaining <= 0) {
					clearInterval(breakTimeInterval);
					// automatically proceed if break time is up
					setTimeout(() => {
						this.proceedToNextTest();
					}, 1000);
					return {
						...prevState,
						multiSectionalInfo: {
							...info,
							breakTimeRemaining: 0,
						},
					};
				}
				return {
					...prevState,
					multiSectionalInfo: {
						...info,
						breakTimeRemaining: newRemaining,
					},
				};
			});
		}, 1000);

		this.setState({ breakTimeInterval });
	};

	proceedToNextTest = async () => {
		this.setState({ loading: true });
		try {
			const data = await this._ModelingTestsController.begin({
				testId: this.modellingId,
				advanceToNextSection: true,
			});

			const info = data.multiSectionalInfo;
			if (!info) {
				this.setState({ loading: false, mode: Mode.end });
				return;
			}
			if (info.isComplete) {
				this.setState({ loading: false, mode: Mode.end });
				return;
			}
			if (info.isBreakTime) {
				this.setState({
					mode: Mode.break,
					loading: false,
					multiSectionalInfo: info,
				});
				this.startBreakTimeCountdown();
				return;
			}

			const testDuration = this.state.modellingTest
				? getDuration(this.state.modellingTest)
				: undefined;
			// Else we have a new test section
			this.setState({
				mode: Mode.test,
				loading: false,
				questions: data.questions,
				texts: data.texts,
				test: data.test,
				defaultCheckedAnswers:
					data.answeredQuestionsInfo?.map((e) => ({
						id: e.id,
						userAnswer: e.userAnswer,
						answeredAt: e.answeredAt,
						numAttempts: e.numAttempts,
						credit: e.credit || 0,
					})) || [],
				multiSectionalInfo: info,
				duration: testDuration,
				startedAt: info.currentSectionTestStartedAt ?? data.startedAt,
			});
		} catch (error) {
			console.error("Failed to proceed to next section", error);
			this.setState({ loading: false });
			alert("Failed to proceed to the next section");
		}
	};

	forceFullyFinish = async () => {
		const test = this.WrapperRef.current?.testComponentRef.current;
		if (!test) return;
		test.safeFinish().catch(() => {
			// ignore
		});
	};

	testTypeSettings: ITestTypeSettings = {
		allowSwitchingToSubmittedQuestions: true,
		allowSwitchingToUnsubmittedQuestions: true,
		checkInBackEnd: false,
		maxNumOfWritingTests: 1,
		showAnswersAt: TestTypeAnswersShowTime.afterDeadline,
		submitAnswerAfterAnswering: false,
	};

	renderTestProgress() {
		const { multiSectionalInfo } = this.state;
		if (!multiSectionalInfo) return null;

		const percent =
			((multiSectionalInfo.currentTestIndex + 1) /
				multiSectionalInfo.totalTests) *
			100;

		return (
			<div
				style={{
					margin: "10px 0",
					padding: "10px",
					textAlign: "center",
					fontFamily: "FiraGO",
					backgroundColor: "#f5f8ff",
					borderRadius: "8px",
				}}
			>
				<div
					style={{
						marginBottom: "5px",
						fontSize: "16px",
						fontWeight: "bold",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						maxWidth: "400px",
						margin: "0 auto",
					}}
				>
					<span>
						ტესტი {multiSectionalInfo.currentTestIndex + 1} /{" "}
						{multiSectionalInfo.totalTests}
					</span>

					{multiSectionalInfo.maxTimePerTest && (
						<span>
							დრო:{" "}
							{Math.floor(multiSectionalInfo.maxTimePerTest / 60)}{" "}
							წუთი
						</span>
					)}
				</div>

				{/* Progress bar */}
				<div
					style={{
						width: "100%",
						height: "8px",
						backgroundColor: "#eee",
						borderRadius: "4px",
						overflow: "hidden",
						maxWidth: "400px",
						margin: "0 auto",
					}}
				>
					<div
						style={{
							width: `${percent}%`,
							height: "100%",
							backgroundColor: "#5273e6",
							borderRadius: "4px",
							transition: "width 0.5s",
						}}
					/>
				</div>
			</div>
		);
	}

	renderBreakTime() {
		const { multiSectionalInfo } = this.state;
		if (
			!multiSectionalInfo?.isBreakTime ||
			multiSectionalInfo.breakTimeRemaining == null
		) {
			return null;
		}

		const minutes = Math.floor(multiSectionalInfo.breakTimeRemaining / 60);
		const seconds = multiSectionalInfo.breakTimeRemaining % 60;
		const currentSection = multiSectionalInfo.currentTestIndex;
		const totalSections = multiSectionalInfo.totalTests;

		return (
			<div
				style={{
					textAlign: "center",
					padding: "30px 20px",
					fontFamily: "FiraGO",
					maxWidth: "600px",
					margin: "0 auto",
				}}
			>
				<h2>შესვენება</h2>
				<p>
					დაასრულეთ ტესტის ნაწილი {currentSection + 1} /{" "}
					{totalSections}
				</p>
				გამოიყენეთ შესვენების დრო
				<div
					style={{
						fontSize: "32px",
						fontWeight: "bold",
						margin: "20px 0",
						color: "#5273e6",
					}}
				>
					{minutes}:{seconds.toString().padStart(2, "0")}
				</div>
				<p style={{ marginBottom: "30px" }}>
					ან გადადით შემდეგ ტესტზე.
				</p>
				<PrimaryButton onClick={this.proceedToNextTest}>
					შემდეგ ტესტზე გადასვლა
				</PrimaryButton>
			</div>
		);
	}

	render() {
		const header = (
			<div style={{ textAlign: "center", marginTop: 20 }}>
				<img
					src={TouLogoSrc}
					alt="eu logo"
					style={{ maxHeight: 120, maxWidth: "90%" }}
				/>
			</div>
		);

		if (this.state.loading || !this.state.modellingTest) {
			return (
				<div style={{ textAlign: "center" }}>
					<SvgLoading />
				</div>
			);
		}

		// Break mode
		if (this.state.mode === Mode.break) {
			return (
				<div style={{ fontFamily: "FiraGo" }}>
					{header}
					{this.renderBreakTime()}
				</div>
			);
		}

		// Beginning mode
		if (this.state.mode === Mode.beginning) {
			if (this.modelingIsFinished()) {
				if (!this.canReviewNow()) {
					return (
						<div style={{ textAlign: "center" }} className="main">
							<h2>მოდელირება დასრულებულია</h2>
						</div>
					);
				}
				return (
					<div
						style={{ textAlign: "center", fontFamily: "FiraGo" }}
						className="main"
					>
						<h2>მოდელირების გადახედვა</h2>
						<PrimaryButton onClick={this.onStartReviewing}>
							დაწყება
						</PrimaryButton>
					</div>
				);
			}
			return (
				<div
					style={{
						textAlign: "center",
						fontFamily: "FiraGO",
					}}
					className="main"
				>
					<div className={styles.largeTextContainer}>
						{header}
						<h1>{this.state.modellingTest.name}</h1>
						<Instructions />
						<br />
						<br />
						<PrimaryButton onClick={this.onBeginTest}>
							დაწყება
						</PrimaryButton>
					</div>
				</div>
			);
		}

		// If in review mode or test mode, we must have questions/texts/test
		if (!this.state.questions || !this.state.texts || !this.state.test) {
			return (
				<div style={{ textAlign: "center" }}>
					<SvgLoading />
				</div>
			);
		}

		return (
			<div style={{ fontFamily: "FiraGo" }}>
				{header}
				{/* For multi-section tests, show progress */}
				{this.state.multiSectionalInfo &&
					this.state.modellingTest?.is_multi_sectional &&
					this.renderTestProgress()}

				<UniversalTestWrapper2
					ref={this.WrapperRef}
					test={this.state.test}
					testTypeSettings={this.testTypeSettings}
					content={{
						cards: [],
						questions: this.state.questions,
						texts: this.state.texts,
					}}
					defaultUserAnswers={this.state.defaultCheckedAnswers || []}
					onFinish={this.onFinish}
					onSave={noopPromise}
					onGotoNext={this.onGotoNext}
					currentAttempt={1}
					FinishPageComponent={
						this.state.isFinishLoading
							? SubmittingSection
							: FinishPageComponent
					}
					testNavigationProps={{
						styles: testDesign,
						showFinishPageIcon: true,
					}}
					preFinishHook={this.preFinishHook}
					startedAt={this.state.startedAt}
					duration={this.state.duration}
					forceFullyFinish={this.forceFullyFinish}
				/>

				{/* Manual "Finish" button outside test navigation (fallback) */}
				{this.state.mode !== Mode.end &&
					this.state.mode !== Mode.review && (
						<div
							className="main"
							style={{
								padding: "0 20px",
								marginTop: "-20px",
								textAlign: "center",
							}}
						>
							<PrimaryButton
								onClick={this.forceFullyFinish}
								style={{ backgroundColor: "#a375ff" }}
							>
								მოდულის დასრულება
							</PrimaryButton>
						</div>
					)}

				<br />
				<br />
				<br />
			</div>
		);
	}

	modelingIsFinished = () => {
		if (!this.state.modellingTest) return false;
		if (this.state.modellingTest.finish_time.getTime() < Date.now()) {
			return true;
		}
		return false;
	};

	canReviewNow = () => {
		if (!this.state.modellingTest) return false;
		if (!this.state.modellingTest.review_start_time) return false;
		if (this.state.modellingTest.review_start_time.getTime() > Date.now()) {
			return false;
		}
		if (!this.state.modellingTest.review_finish_time) return true;
		return (
			this.state.modellingTest.review_finish_time.getTime() > Date.now()
		);
	};

	onGotoNext = () => {
		// This can be used to go to next question or next section if needed
		// For multi-section logic, we rely on break or proceed functions
	};

	WrapperRef = React.createRef<UniversalTestWrapper2>();
}

const noopPromise = () => {
	return new Promise<void>((resolve) => {
		resolve();
	});
};

const Instructions = React.memo(function Instructions() {
	return (
		<span style={{ display: "block", textAlign: "center" }}>
			ტესტის შესახებ ინფორმაცია
		</span>
	);
});

export default connect<null, null, IOwnProps>(null, null)(Modelling);

const SubmittingSection = () => {
	return (
		<div
			style={{
				textAlign: "center",
				fontFamily: "FiraGo",
				marginTop: 20,
			}}
		>
			<SvgLoading />
			<br />
			<br />
			<p>მონაცემების ინახება...</p>
		</div>
	);
};

const FinishPageComponent: React.FC<IFinishPageProps> = (props) => {
	/* const maxScore = useMemo(() => {
		try {
			let sum = 0;
			for (const q of props.questions) {
				sum += newContent((q as IFullQuestion).content).getMaxCredit();
			}
			return sum;
		} catch (e) {
			console.warn(e);
		}
		return 0;
	}, [props.questions]);

	const score = props.info.totalCredit; */

	return (
		<div>
			<div
				style={{
					fontFamily: "FiraGo",
					textAlign: "center",
					color: "#5273e6",
					fontSize: 22,
				}}
			>
				<br />
				სწორი პასუხების ნახვას შეძლებ:
				<br />
				<br />
				<b>ორშაბათს, 7 ივნისს, 12:00-დან დღის ბოლომდე.</b>
			</div>
		</div>
	);
};

const getDuration = (modellingTest: IModelingTest) => {
	// If multi-sectional, we might use the per-test duration
	if (modellingTest.is_multi_sectional) {
		return modellingTest.max_time_per_test;
	}
	return modellingTest.max_duration;
};
