import Joi from "@app/utils/joi";
import { UserEssentialsSchema, IUserEssentials } from "../users/helper-schemas";

/////

export const APOSTLoginSchema = Joi.object({
	mobile: Joi.number(),
	mail: Joi.string(),
	password: Joi.string().required(),
});

export interface IAPOSTLogin {
	password: string;
	mobile?: number;
	mail?: string;
}

export const RPOSTLoginSchema = UserEssentialsSchema.keys({
	accessToken: Joi.string().required(),
	refreshToken: Joi.string().required(),
});
export interface IRPOSTLogin extends IUserEssentials {
	accessToken: string;
	refreshToken: string;
}

/////

export const APUTLoginByAccessTokenSchema = Joi.object({
	murtskuUserId: Joi.number().required(),
	token: Joi.string().required(),
});
export interface IAPUTLoginByAccessToken {
	murtskuUserId: number;
	token: string;
}

export const RPUTLoginByAccessTokenSchema = RPOSTLoginSchema;
export type IRPUTLoginByAccessToken = IRPOSTLogin;

/////

export const APOSTLogoutSchema = Joi.object({
	userId: Joi.number().required(),
	refreshToken: Joi.string().required(),
});

export interface IAPOSTLogout {
	userId: number;
	refreshToken: string;
}
export type IRPOSTLogout = void;

/////

export const APUTAccessTokensSchema = Joi.object({
	userId: Joi.number().required(),
	refreshToken: Joi.string().required(),
	accessToken: Joi.string().required(),
});
export interface IAPUTAccessTokens {
	userId: number;
	refreshToken: string;
	accessToken: string;
}

export const RPUTAccessTokensSchema = RPOSTLoginSchema;
export type IRPUTAccessTokens = IRPOSTLogin;

// Murtsku login validators
export const APOSTMurtskuLoginSchema = Joi.object({
	mail: Joi.string(),
	mobile: Joi.number(),
	password: Joi.string(),
});
export interface IAPOSTMurtskuLogin {
	mail?: string;
	mobile?: number;
	password?: string;
}

export const RPOSTMurtskuLoginSchema = Joi.object()
	.keys({
		id: Joi.number().integer(),
		murtsku_id: Joi.number().integer().allow(null),
		username: Joi.string(),
		firstname: Joi.string().allow(null),
		lastname: Joi.string().allow(null),
		mail: Joi.string(),
		mob: Joi.string(),
		vld_mob: Joi.number().integer(),
		type: Joi.string().valid("DEVELOPER", "ADMIN", "UNIADMIN", "USER"),
		hashed_password: Joi.string(),
		hash_algo: Joi.string().allow(null),
		city: Joi.number().integer().allow(null),
		school: Joi.number().integer().allow(null),
		terms: Joi.number().integer(),
		created_at: Joi.date(),
		updated_at: Joi.date(),
	})
	.allow(null);
export type IRPOSTMurtskuLogin = {
	id?: number;
	murtsku_id?: number | null;
	username?: string;
	firstname?: string | null;
	lastname?: string | null;
	mail?: string;
	mob?: string;
	vld_mob?: number;
	type?: "DEVELOPER" | "ADMIN" | "UNIADMIN" | "USER";
	hashed_password?: string;
	hash_algo?: string | null;
	city?: number | null;
	school?: number | null;
	terms?: number;
	created_at?: Date;
	updated_at?: Date;
} | null;

// Murtsku login access token validators
export const APUTMurtskuTokenSchema = Joi.object({
	murtskuUserId: Joi.number().integer().required(),
	API_KEY: Joi.string().required(),
	firstname: Joi.string().allow(null).required(),
	lastname: Joi.string().allow(null).required(),
	mobile: Joi.string().allow(null).required(),
	username: Joi.string().required(),
	boughtCourses: Joi.array().items(Joi.number()),
});
export interface IAPUTMurtskuToken {
	murtskuUserId: number;
	API_KEY: string;
	firstname: string | null;
	lastname: string | null;
	mobile: string | null;
	username: string;
	boughtCourses?: number[];
}

export const RPUTMurtskuTokenSchema = Joi.object({
	token: Joi.string(),
	murtskuUserId: Joi.number().integer(),
});
export interface IRPUTMurtskuToken {
	token?: string;
	murtskuUserId?: number;
}
